%commissionSpriteCommon {
  background-image: url("./commission-sprite.x1.5.png");
  background-repeat: no-repeat;
  background-size: 216px 341px;
}
.commissionSpritePercent {
  @extend %commissionSpriteCommon;
  width: 216px;
  height: 258px;
  background-position: 0 0;
}
.commissionSpriteZero {
  @extend %commissionSpriteCommon;
  width: 80px;
  height: 83px;
  background-position: 0 -258px;
}
.commissionSpriteZeroSmall {
  @extend %commissionSpriteCommon;
  width: 51px;
  height: 52px;
  background-position: -80px -258px;
}
