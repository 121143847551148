@import "assets/css/variables.scss";

.header {
  position: fixed;
  z-index: 2;
  padding: 20px 30px 0;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;

  @include for-phone-tablet {
    border-bottom: 0.5px solid #d6d9e1;
    padding: 10px;
  }

  a {
    display: inline-block;
    vertical-align: top;
  }
}

.logo {
  display: block;

  @include for-phone-tablet {
    width: 46px;
    height: auto;
  }
}
