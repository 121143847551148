@import "assets/css/variables.scss";

.tab {
  width: 100%;
  display: block;

  .tabNavigations {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    overflow-y: auto;

    .tabTitle {
      margin-bottom: 10px;
      display: flex;
      outline: none;
      border: none;
      background: none;
      gap: 5px;
      flex: 0 0 auto;

      @include for-phone {
        &:first-child {
          margin-inline-start: 12px !important;
        }

        &:last-child {
          margin-inline-end: 12px !important;
        }
      }

      > span {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        background-color: #e8e8e8;
        border: 1px solid #b4b4b4;
        border-radius: 20px;
        padding: 9px 20px;
        cursor: pointer;
        height: 100%;
        box-sizing: border-box;
        transition-property: background-color, border-color;
        transition-timing-function: ease-in-out;
        transition-duration: 300ms;
      }

      &.tabTitleShow {
        > span {
          background-color: #ffffff;
          border-color: #ffffff;
        }
      }
    }
  }

  .tabContent {
    .tabDescription {
      padding: 0;
      margin: 0;
      transition: height ease-in-out 300ms;
      height: 0;
      overflow: hidden;
      box-sizing: border-box;
      display: none;
    }

    .tabDescriptionShow {
      display: block;
      height: auto;
    }
  }
}
