@import "assets/css/variables.scss";
@value box, boxRight, boxWrap, boxTitle, boxImage, boxContent, boxScrollable, boxTransition from "../../../components/Box/Box.module.scss";

$x0: 325px;
$y0: 0px;

%animation-common {
  position: absolute;
  transition:
    transform ease-in-out 300ms,
    left ease-in-out 300ms,
    top ease-in-out 300ms;
  transform-origin: center;
}

@mixin animation($x1, $y1, $r1, $x2, $y2, $r2) {
  .boxIntegration & {
    @extend %animation-common;
    left: $x1 - $x0;
    top: $y1 + $y0;
    transform: rotate($r1);
  }
  .boxIntegration:not(.boxScrollable):hover &,
  .boxIntegration.boxTransition & {
    left: $x2 - $x0;
    top: $y2 + $y0;
    transform: rotate($r2);
  }
}

.box.boxRight.boxIntegration {
  overflow: hidden;
  grid-column: span 18;

  @include for-phone {
    flex-direction: column !important;
  }

  @include for-tablet {
    flex-direction: column !important;
  }

  @include for-tablet-s {
    flex-direction: row !important;
  }

  .boxWrap {
    @include for-phone-tablet {
      padding-top: 30px;
      padding-bottom: 30px;
      gap: 12px;
    }
  }

  .boxTitle {
    text-align: center;
  }

  .boxImage {
    position: relative;
    width: 224px;
    height: 420px;

    @include for-phone-tablet {
      width: 100%;
      height: 199px;
    }

    @include for-tablet-s {
      width: 50%;
      height: 320px;
    }
  }

  .boxContent {
    padding-left: 20%;
  }
}

$y3: 40px;
$y4: -20px;

.rocket {
  @include animation(
    171px,
    233px + $y3,
    -15deg,
    198.32px,
    191.08px + $y3,
    -15deg
  );

  @include for-phone-tablet {
    @include animation(171px, 200px, 28deg, 477.32px, 16.92px, -16deg);

    width: 295px;
    height: auto;
  }
}

.integrationArrowSmall {
  @include animation(
    389.02px,
    244.86px + $y4,
    -7.422deg,
    392.39px,
    227.23px + $y4,
    0deg
  );

  @include for-phone-tablet {
    @include animation(389.02px, 244.86px + $y4, -7.422deg, 430px, 147px, 0deg);

    width: 56px;
    height: auto;
  }
}

.integrationArrowMiddle {
  @include animation(
    331.81px,
    96.33px + $y4,
    -9.237deg,
    294.16px,
    111.34px + $y4,
    -15deg
  );

  @include for-phone-tablet {
    @include animation(331.81px, 96.33px + $y4, -9.237deg, 348px, 53px, -15deg);

    width: 70px;
    height: auto;
  }
}

.integrationArrowBig {
  @include animation(
    394.55px,
    55.66px + $y4,
    -5.334deg,
    358px,
    63px + $y4,
    0deg
  );

  @include for-phone-tablet {
    @include animation(394.55px, 55.66px + $y4, -5.334deg, 457px, 14px, 0deg);

    width: 134px;
    height: auto;
  }
}
