@import "assets/css/variables.scss";

@value box, boxImage from "../Box/Box.module.scss";

.scrollableCarousel {
  padding-bottom: 0 !important;
  overflow-y: visible;
  [data-overlayscrollbars-contents] {
    display: flex;
    gap: 20px;
    border-radius: 20px;
    margin-bottom: 24px !important;

    @include for-phone {
      border-radius: 0;
    }

    > .box {
      min-width: 432px;
      width: 432px;
      height: 488px;
      margin: 0;

      @include for-phone {
        min-width: 350px;
        width: 350px;
      }

      .boxImage {
        text-align: center;
        margin-top: auto;
      }
    }
  }
}

.scrollableCarouselArrows {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  gap: 22px;

  @include for-phone {
    display: none;
  }

  .scrollableCarouselArrowButton {
    background-color: #ffffff;
    width: 38px;
    height: 38px;
    border-radius: 38px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 25%);
    border: none;
    transition-property: box-shadow, transform, background-color, color;
    transition-timing-function: ease-in-out;
    transition-duration: 160ms;
    transform: translateY(0);
    outline: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #000000;

    &:active:not(:disabled) {
      transform: translateY(1px);
      box-shadow: 0 1px 5px rgba(0, 0, 0, 25%);
    }

    &:disabled {
      color: #96979d;
      background-color: #f7f7f7;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 25%);
    }
  }
}

.osThemeCarousel {
  box-sizing: border-box;
  --os-size: 4px;
  --os-padding-perpendicular: 0;
  --os-padding-axis: 0;
  --os-track-border-radius: 4px;
  --os-handle-interactive-area-offset: 4px;
  --os-handle-border-radius: 4px;
  --os-track-bg: #bfbfbf;
  --os-track-border: #bfbfbf;
  --os-track-bg-hover: #bfbfbf;
  --os-track-border-hover: #bfbfbf;
  --os-track-bg-active: #bfbfbf;
  --os-track-border-active: #bfbfbf;
  --os-handle-bg: #000000;
  --os-handle-bg-hover: #000000;
  --os-handle-bg-active: #000000;

  @include for-phone {
    --os-padding-axis: 12px;
  }
}
