@import "assets/css/variables.scss";

@value box, boxTitle, boxContent from "../Box/Box.module.scss";

.tab {
  .boxTitle {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 20px;
  }
}

.box {
  padding-top: 20px;

  .boxTitle,
  .boxContent {
    padding-right: 100px;
  }

  @include for-phone {
    &:first-child {
      margin-inline-start: 12px !important;
    }

    &:last-child {
      margin-inline-end: 12px !important;
    }
  }
}
