@import "assets/css/variables.scss";
@import "components/PrimaryBlock/primaryBlockLineGradient";
@value mainFunctionSpriteGreen, mainFunctionSpriteBlue, mainFunctionSpriteWhite from "../../../assets/main-function-sprite.scss";
@value box, boxImage, boxContent, boxScrollable, boxTransition from "../../../components/Box/Box.module.scss";

%mainFunctionAnimation {
  position: absolute;
  transition-property: left, top;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  transform-origin: center;
}

@mixin animation($x1, $y1, $x2, $y2) {
  .mainFunction & {
    @extend %mainFunctionAnimation;
    left: $x1;
    top: $y1;
  }
  .mainFunction.boxTransition &,
  .mainFunction:not(.boxScrollable):hover & {
    left: $x2;
    top: $y2;
  }
}

.mainFunction {
  overflow: hidden;
  grid-column: span 12;

  @include for-phone-tablet {
    flex: 0 0 350px;
  }

  .boxImage {
    background-image: primaryBlockLineGradient(129.5deg, 61px, #0de5b6);
    position: relative;
    width: 100%;
    height: 187px;
  }

  .boxContent {
    text-align: start !important;
    padding-inline-start: 110px;

    @include for-phone-tablet {
      padding-inline: 60px;
    }
  }
}

.mainFunctionSpriteGreen {
  @include animation(109px, -5px, 49px, -5px);
}
.mainFunctionSpriteBlue {
  @include animation(-38px, 86px, -18px, 86px);
}
.mainFunctionSpriteWhite {
  @include animation(237px, 0, 177px, 0);
}
