@import "assets/css/variables.scss";
@value box, boxImage, boxScrollable, boxTransition from "../../../components/Box/Box.module.scss";

%apiConnectionAnimation {
  position: absolute;
  transition:
    left ease-in-out 300ms,
    top ease-in-out 300ms;
  transform-origin: center;
}

@mixin animation($x1, $y1, $x2, $y2) {
  .apiConnection & {
    @extend %apiConnectionAnimation;
    left: calc(50% + $x1);
    top: $y1;
  }
  .apiConnection.boxTransition &,
  .apiConnection:not(.boxScrollable):hover & {
    left: calc(50% + $x2);
    top: $y2;
  }
}

.apiConnection {
  overflow: hidden;

  @include for-phone-tablet {
    flex: 0 0 350px;
  }

  .boxImage {
    position: relative;
    width: 100%;
    height: 187px;
  }
}

img {
  @include animation(-102px, 46px, -102px, -9px);
}
