@import "assets/css/variables.scss";

.image {
  padding: 40px 40px 0;
  margin-bottom: 14px;

  @include for-phone-tablet {
    padding: 30px 30px 0;
  }
}
