@import "assets/css/variables.scss";

.group {
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin-bottom: 12px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
}

.inputs {
  display: flex;
  gap: 20px;

  @include for-phone-tablet {
    flex-direction: column;
  }
}
