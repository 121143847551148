.container {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
}

.spinnerWrapper {
  display: flex;
  margin: auto;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 54px 0px rgba(0, 0, 0, 0.25);
}
