@import "assets/css/variables.scss";

.requestAccepted {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  padding: 100px 155px;

  @include for-tablet {
    padding: 60px 20px;
  }

  @include for-phone {
    padding: 60px 8px;
  }

  h3 {
    font-size: 24px;
    line-height: 32px;
  }
}
