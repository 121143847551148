@import "assets/css/variables.scss";

.page {
  padding: 124px 0 22px 50%;
  width: 100dvw;
  min-height: 100dvh;
  box-sizing: border-box;
  background-color: #fff;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
  }

  &::before {
    background-image: linear-gradient(
        129.55deg,
        transparent,
        transparent calc(50% - 100px),
        #0029ff calc(50% - 100px),
        #0029ff 100%
      ),
      linear-gradient(
        43.5deg,
        transparent,
        transparent calc(50% - 50px),
        #0de5b6 calc(50% - 50px),
        #0de5b6 100%
      );
    background-position:
      center 0,
      center 0;
    background-size:
      100% 100%,
      100% 100%;
    background-repeat: no-repeat;
    border-radius: 0 0 25px 0;
  }

  &::after {
    background-image: var(--bg-image);
    background-position: calc(50% - 50px) calc(50% - 10px);
    background-repeat: no-repeat;
    background-size: 90%;
  }

  @include for-phone-tablet {
    padding: 98px 0 22px;
    background-image: none;

    &::before,
    &::after {
      display: none;
    }
  }

  @include for-phone {
    padding: 65px 0;
  }
}

.container {
  position: relative;
  max-width: 417px;
  margin: 0 auto 0 0;
  padding: 0 0 0 75px;

  @include for-tablet {
    margin: 0 auto;
    padding: 0 20px;
  }

  @include for-phone {
    margin: 0 auto;
    padding: 0 8px;
  }
}

.title {
  margin-bottom: 20px;
}

.subtitle {
  margin-bottom: 30px;

  a {
    color: #000;
  }
}
