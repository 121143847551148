@import "assets/css/variables.scss";

%riskMitigationSprite {
  background-image: var(--bg-image);
  background-repeat: no-repeat;
  background-size: 314px 512px;

  @include for-phone {
    background-size: 200px 319px;
  }
}

.riskMitigationSpriteAntifrod {
  @extend %riskMitigationSprite;
  width: 149px;
  height: 205px;
  background-position: 0 0;

  @include for-phone {
    width: 95px;
    height: 128px;
  }
}

.riskMitigationSpriteAml {
  @extend %riskMitigationSprite;
  width: 165px;
  height: 172px;
  background-position: -149px 0;

  @include for-phone {
    width: 110px;
    height: 110px;
    background-position: -95px 1px;
  }
}

.riskMitigationSpriteCompliance {
  @extend %riskMitigationSprite;
  width: 299px;
  height: 307px;
  background-position: 0 -205px;

  @include for-phone {
    width: 179px;
    height: 188px;
    background-position: -8px -129px;
  }
}
