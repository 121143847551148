@import "assets/css/variables.scss";

@value freeConnectSpriteInput, freeConnectSpriteFront, freeConnectSpriteInputLabel, freeConnectSpriteThumb from "../../../assets/free-connect-sprite.scss";
@value box, boxWrap, boxImage, boxTitle, boxScrollable, boxTransition from "../../../components/Box/Box.module.scss";

%animation-common {
  position: absolute;
  //transition: left ease-in-out 300ms, top ease-in-out 300ms, opacity linear 300ms;
  transition-property: left, top, opacity;
  transition-timing-function: ease-in-out, ease-in-out, linear;
  transition-duration: 300ms;
}

@mixin animation($x1, $y1, $o1, $x2, $y2, $o2) {
  .freeConnectBox & {
    @extend %animation-common;
    left: $x1;
    top: $y1;
    opacity: $o1;
  }
  .freeConnectBox.boxTransition &,
  .freeConnectBox:not(.boxScrollable):hover & {
    left: $x2;
    top: $y2;
    opacity: $o2;
  }
}

.freeConnectBox {
  overflow: hidden;
  grid-column: span 14;

  @include for-tablet-s {
    flex-direction: row;
  }

  .boxWrap {
    padding-top: 20px;

    @include for-phone {
      text-align: center;
      padding-bottom: 50px;
    }

    @include for-tablet-s {
      padding-bottom: 50px;
    }
  }

  .boxImage {
    margin-top: 40px;
    margin-left: 40px;
    width: 144px;
    height: 92px;
    position: relative;
    will-change: contents;

    @include for-phone {
      margin: 30px auto 10px;
    }

    @include for-tablet-s {
      width: 100%;
      height: auto;
    }
  }

  .boxTitle {
    @include for-tablet {
      font-size: 32px;
      line-height: 40px;
    }
  }
}

.freeConnectSpriteInputLabel {
  @include animation(34px, 45px, 0, 34px, 45px, 1);
}

.freeConnectSpriteInput {
  @include animation(0, 0, 1, 0, 0, 1);
}

.freeConnectSpriteFront {
  @include animation(16px, 20px, 1, 81.14px, 10.29px, 0);
}

.freeConnectSpriteThumb {
  @include animation(16px, 20px, 1, 81.14px, 10.29px, 1);
}
