@import "assets/css/variables.scss";
@value box, boxRight, boxWrap, boxTitle, boxImage, boxContent, boxScrollable, boxTransition from "../../../components/Box/Box.module.scss";

$x0: 325px;
$y0: 0px;

%animation-common {
  position: absolute;
  transition:
    transform ease-in-out 300ms,
    left ease-in-out 300ms,
    top ease-in-out 300ms;
  transform-origin: center;
}

@mixin animation($x1, $y1, $r1, $x2, $y2, $r2) {
  .agreementBox & {
    @extend %animation-common;
    left: $x1 - $x0;
    top: $y1 + $y0;
    transform: rotate($r1);
  }

  .agreementBox:not(.boxScrollable):hover &,
  .agreementBox.boxTransition & {
    left: $x2 - $x0;
    top: $y2 + $y0;
    transform: rotate($r2);
  }
}

.box.boxRight.agreementBox {
  overflow: hidden;
  grid-column: span 18;

  @include for-phone-tablet {
    flex-direction: column !important;
  }

  @include for-tablet-s {
    flex-direction: row !important;
  }

  .boxWrap {
    @include for-phone-tablet {
      padding-top: 30px;
      padding-bottom: 30px;
      gap: 12px;
    }
  }

  .boxTitle {
    text-align: center;
  }

  .boxImage {
    position: relative;
    width: 224px;
    height: 420px;

    @include for-tablet {
      width: 100%;
      height: 199px;
    }

    @include for-phone {
      width: 100%;
      height: 227px;
    }

    @include for-tablet-s {
      width: 100%;
      height: 292px;
    }
  }

  .boxContent {
    padding-left: 20%;
  }
}

.agreementDoneMiddle {
  @include animation(355px, 184px, 0deg, 345px, 154px, 0deg);

  @include for-phone-tablet {
    @include animation(337px, 66px, 0deg, 337px, 36px, 0deg);
  }
}

.agreementDoneSmall {
  @include animation(524px, 105px, 0deg, 514px, 55px, 0deg);

  @include for-phone-tablet {
    @include animation(599px, 77px, 0deg, 599px, -7px, 0deg);
  }
}

.agreementCheck {
  @include animation(483px, 155px, 0deg, 473px, 145px, 0deg);

  @include for-phone-tablet {
    @include animation(677px, 138px, 0deg, 677px, 108px, 0deg);
  }
}

.agreementDoneBig {
  @include animation(387px, 271px, 0deg, 377px, 231px, 0deg);

  @include for-phone-tablet {
    @include animation(512px, 80px, 0deg, 512px, 50px, 0deg);
  }
}

.agreementBg {
  .agreementBox & {
    position: absolute;
    transition: opacity ease-in-out 300ms;
    transform-origin: center;
    opacity: 0;
    left: 0;
    top: 106px;

    @include for-phone-tablet {
      left: 82px;
      top: -37px;
    }
  }

  .agreementBox:not(.boxScrollable):hover &,
  .agreementBox.boxTransition & {
    opacity: 1;
  }
}
