@import "assets/css/variables.scss";

.menu {
  display: flex;
  flex-direction: row;
  gap: 90px;

  @include for-phone-tablet {
    display: none;

    &.active {
      display: block;
      position: absolute;
      top: 50px;
      background: #fcfcfd;
      left: 0;
      width: 100%;
      height: 544px;
      border-radius: 0 0 20px 20px;
      box-shadow: 0px 7px 19px 0px rgba(0, 0, 0, 0.25);
      padding: 40px 20px;
      box-sizing: border-box;
      padding: 35px 20px;
      box-sizing: border-box;
      gap: 36px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @include for-phone {
    &.active {
      top: 42px;
    }
  }

  & > a {
    color: #000;
    display: block;
    text-decoration: none;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    padding: 24px 0 18px;
    border-bottom: 4px solid rgba(#000, 0);
    transition: border-bottom-color 200ms ease-in-out;

    @include for-phone-tablet {
      font-size: 21px;
      line-height: 34px;
      padding: 0;
    }

    &.active {
      border-bottom-color: rgba(#000, 1);
    }
  }
}
