@import "assets/css/variables.scss";

.select {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 62px;
}

.container {
  position: relative;

  @include for-phone {
    min-width: auto;
  }
}

.label {
  position: absolute;
  left: 10px;
  top: 13px;
  transition: 0.2s all;
  color: #96979d;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 20px);
  user-select: none;
  cursor: text;
}

.filled {
  .label {
    transform: translateY(-50%);
    font-size: 12px;
    line-height: 16px;
  }
}

.field {
  padding: 20px 10px 2px;
  width: 100%;
  box-sizing: border-box;
  color: #000;
  font-size: 14px;
  line-height: 20px;
  border-radius: 5px;
  border: 1px solid #d6d9e1;
  appearance: none;
  background-color: transparent;
  background-image: url(../../assets/icon-select-arrow.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 10px;
  cursor: pointer;
  position: relative;
  z-index: 2;

  &:focus,
  &:focus-visible {
    outline: 1px solid #1336fa;

    & + .label {
      color: #000;
      transform: translateY(-50%);
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.invalid {
  border-color: #f40000;
}

.info,
.error {
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}

.info {
  color: #373948;
}

.error {
  color: #f40000;
}
