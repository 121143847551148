@import "assets/css/variables.scss";

.checkbox {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 62px;
}

.container {
  position: relative;

  @include for-phone {
    min-width: auto;
  }
}

.label {
  font-size: 10px;
  line-height: 14px;
  display: block;
  cursor: pointer;
  display: flex;
  gap: 10px;

  a {
    color: #000;
  }
}

.qwe {
  position: relative;
  flex: 0 0 18px;

  &::before,
  &::after {
    content: "";
    background-color: #fff;
    border-radius: 5px;
    width: 18px;
    height: 18px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px solid #000;
    transform: translate(-50%, -50%);
    box-sizing: content-box;
    z-index: 1;
  }

  &::after {
    display: none;
    outline: none;
    background-image: url(../../assets/icon-check-white.svg);
    background-color: #000;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    z-index: 2;
  }
}

.field {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;

  &:focus,
  &:focus-visible {
    & + .label .qwe {
      &::before {
        outline: 1px solid #1336fa;
      }
    }
  }

  &:checked {
    & + .label .qwe {
      &::after {
        display: block;
      }
    }
  }
}

.invalid {
  & + .label .qwe {
    &::before {
      border: 1px solid #f40000;
    }
  }
}

.error {
  color: #f40000;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}
