@import "assets/css/variables.scss";

.box {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  // gap: 14px;
  position: relative;
  box-sizing: border-box;
  flex: 1 1 0;

  &.boxScrollable.boxTransition {
    position: relative;
  }
  > *:first-child {
    padding-top: 50px;

    @include for-phone {
      padding-top: 30px;
    }
  }
  .boxTitle,
  .boxContent {
    margin: 0 0 30px;
    padding-inline: 40px;

    @include for-phone {
      margin: 0 0 12px;
      padding-inline: 30px;
    }
  }
  .boxTitleNarrowed {
    padding-inline: 60px;
  }
  .boxContent {
    margin: 0;

    ul {
      list-style-type: "– ";
      padding-left: 10px;
    }
  }
  .boxImage {
  }
  &.boxDark {
    background: linear-gradient(0deg, #0e1238 0%, #0e1238 100%), #0f1125;
    color: #ffffff;
  }
  &.boxLight {
    background: #eff0f7;
  }
  &.boxWhite {
    background: #ffffff;
  }
  &.boxYellow {
    background: #efff03;
  }
  &.boxGreen {
    background: #0de5b6;
  }
  &.boxBlue {
    background: #1336fa;
  }
  &.boxCyan {
    background: #91c6d2;
  }
  &.boxRight {
    flex-direction: row;
    justify-content: space-between;

    gap: 0;
    & > div:first-child {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
    .boxTitle {
      margin: 0;
      //height: ;
      //border: 1px solid;
    }
    .boxContent {
      //border: 1px solid;
    }
    .boxImage {
      width: 224px;
      height: 420px;
      //border: 1px solid;
    }
  }
  &.boxTop {
    *:first-child {
      order: 1;
    }
    .boxImage {
      order: 0;
    }
  }
  &.boxBottom {
    .boxImage {
      margin-top: auto;
    }
  }
  .boxNumber {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    background: #000000;
    color: #ffffff;
    display: block;
    text-align: center;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 16px;
    padding: 22px 11px;
    font-weight: 600;
  }
  &:not(.boxTop, .boxRight, .boxBottom) {
    .boxWrap {
      width: 100%;
    }
  }

  &.contentCentered {
    text-align: center;
  }

  .boxButtonsGroup {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 14px;
  }
}
