@import "assets/css/variables.scss";

.container {
  display: flex;
  gap: 10px;
  justify-content: stretch;
  align-items: center;
}

.slide {
  flex: 1 1 auto;
  height: 6px;
  border-radius: 5px;
  background: #ececec;
  overflow: hidden;
  position: relative;

  &::before,
  &::after {
    content: "";
    display: block;
    height: 100%;
    width: 1px;
    background-color: #fff;
    left: 20%;
    top: 0;
    position: absolute;
  }

  &::after {
    left: 40%;
  }
}

.indicator {
  height: 100%;
  transition: width 0.3s ease-in-out;

  &::before,
  &::after {
    content: "";
    display: block;
    height: 100%;
    width: 1px;
    background-color: #fff;
    left: 60%;
    top: 0;
    position: absolute;
  }

  &::after {
    left: 80%;
  }
}
