.iconCircle {
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  border-radius: 80px;
  background-color: #ededed;
  padding: 15px;
  &.iconCircleLight {
    background-color: #ededed;
    color: #ffffff;
  }
  &.iconCircleDark {
    background-color: #000000;
    color: #ffffff;
  }
}
