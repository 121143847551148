%freeConnectSpriteCommon {
  background-image: var(--bg-image);
  background-repeat: no-repeat;
  background-size: 288px 92px;
}
.freeConnectSpriteInput {
  @extend %freeConnectSpriteCommon;
  width: 144px;
  height: 92px;
  background-position: 0 0;
}
.freeConnectSpriteFront {
  @extend %freeConnectSpriteCommon;
  width: 72px;
  height: 73px;
  background-position: -144px 0;
}
.freeConnectSpriteThumb {
  @extend %freeConnectSpriteCommon;
  width: 72px;
  height: 73px;
  background-position: -216px 0;
}
.freeConnectSpriteInputLabel {
  @extend %freeConnectSpriteCommon;
  width: 37px;
  height: 18px;
  background-position: -144px -73px;
}
