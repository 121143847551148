@import "assets/css/variables.scss";
@value boxWrap, boxRight, boxImage, boxTitle, boxContent from "../../components/Box/Box.module.scss";

.nav {
  display: flex;
  gap: 7px;
  margin-bottom: 30px;
  overflow-y: auto;
  justify-content: center;
  align-items: center;

  @include for-phone-tablet {
    justify-content: flex-start;
    margin-bottom: 20px;
    position: sticky;
    top: 42px;
    z-index: 5;
    background-color: #f8f8f8;
    backdrop-filter: blur(7.5px);
    padding: 7px 0;
  }

  @include for-tablet {
    justify-content: center;
    align-items: center;
  }

  > a {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    border: 1px solid #fff;
    background-color: #fff;
    color: black;
    text-decoration: none;
    border-radius: 20px;
    padding: 8px 20px;
    cursor: pointer;
    height: 40px;
    box-sizing: border-box;
    transition-property: background-color, border-color;
    transition-timing-function: ease-in-out;
    transition-duration: 300ms;
    white-space: nowrap;

    @include for-phone {
      &:first-child {
        margin-inline-start: 7px !important;
      }

      &:last-child {
        margin-inline-end: 7px !important;
      }
    }

    &.active {
      background-color: #e8e8e8;
      border-color: #b4b4b4;
    }
  }
}

.advantages {
  display: grid;
  grid-template-columns: repeat(36, 1fr);
  gap: 20px 22px;

  @include for-phone-tablet-s {
    display: flex;
  }
}

.support {
  padding-top: 40px;
  flex-direction: column;
  margin-bottom: 30px;
  gap: 0;

  @include for-phone {
    padding-top: 25px;
    margin-bottom: 20px;
  }

  h3 {
    text-align: center;
    margin-bottom: 10px;

    @include for-phone {
      font-size: 30px;
      line-height: 40px;
      padding-inline: 30px;
    }
  }

  a {
    color: black;
  }

  .roundClock {
    text-align: center;
    margin-bottom: 32px;

    @include for-phone {
      margin-bottom: 24px;
    }

    p {
      border-radius: 30px;
      padding: 10px 30px;
      background: #ffffff;
      display: inline-flex;
      margin: 0;
    }
  }
}

.contacts {
  display: flex;
  gap: 135px;
  justify-content: center;
  align-items: center;

  @include for-tablet {
    flex-flow: wrap;
    gap: 30px;
  }

  @include for-phone {
    flex-direction: column;
    gap: 34px;
  }
}

.contactsTelegram {
  margin: 0;
  line-height: 24px;

  svg {
    vertical-align: top;
    margin-right: 10px;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.contactsEmail {
  font-size: 21px;
  line-height: 24px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.contactsImg {
  margin: 0 auto 15px;
  width: 100%;
  height: auto;
  max-width: 637px;
}

.supportFooter {
  display: flex;
  justify-content: center;
  gap: 60px;

  @include for-tablet {
    flex-flow: wrap;
    row-gap: 30px;
  }

  @include for-phone {
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }
}

.supportFooterItem {
  display: flex;
  gap: 14px;
  align-items: center;
  flex: 0 0 248px;

  &:nth-child(2) {
    flex: 0 0 195px;
  }

  @include for-phone {
    flex: 0 0 auto !important;
    width: 250px;
  }
}

.functionalHeader {
  @include for-phone {
    display: none;
  }
}

.functional {
  display: grid;
  grid-template-columns: repeat(36, 1fr);
  gap: 15px;
  margin-bottom: 20px;

  @include for-phone-tablet {
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
  }

  .boxWrap {
    padding-top: 40px;
  }

  .boxTitle,
  .boxContent {
    text-align: center;
  }
}

.howToConnect {
  flex-direction: column;

  .howToConnectHeader {
    text-align: center;

    h2 {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 8px;
    }
  }

  .howToConnectBody {
    flex-direction: row;
    width: 100%;
    text-align: center;

    .boxWrap {
      padding-block: 80px 50px;

      @include for-phone {
        padding-block: 40px 50px;
      }

      p {
        margin: 19px 0 0;
      }
    }
  }

  .howToConnectSteps {
    display: flex;
    justify-content: space-around;
    margin-bottom: 60px;
    list-style: none !important;

    @include for-phone {
      flex-direction: column;
      gap: 30px;
      margin-bottom: 40px;
    }

    @include for-tablet {
      flex-flow: wrap;
      gap: 25px;
    }

    li {
      flex: 1 1 auto;
    }
  }
}
