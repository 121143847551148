@import "assets/css/variables.scss";

@value boxImage from "../../../components/Box/Box.module.scss";

%animation-common {
  position: absolute;
  transition:
    transform ease-in-out 300ms,
    left ease-in-out 300ms,
    top ease-in-out 300ms;
  transform-origin: center;
}

@mixin animation($x1, $y1, $r1, $x2, $y2, $r2) {
  .preProcessingBox & {
    @extend %animation-common;
    left: $x1;
    top: $y1;
    transform: rotate($r1);
  }

  .preProcessingBox:hover & {
    left: $x2;
    top: $y2;
    transform: rotate($r2);
  }
}

.api {
  @include animation(16px, 151px, 0deg, 16px, 90px, 0deg);

  @include for-phone {
    width: 82px;
    height: auto;

    @include animation(16px, 130px, 0deg, 13px, 82px, 0deg);
  }
}

.antifrodRound {
  @include animation(182px, 91px, 0deg, 182px, 61px, 0deg);

  @include for-phone {
    width: 121px;
    height: auto;

    @include animation(124px, 91px, 0deg, 124px, 61px, 0deg);
  }
}

.complianceDark {
  @include animation(274px, 135px, 0deg, 274px, 64px, 0deg);

  @include for-phone {
    width: 115px;
    height: auto;

    @include animation(188px, 110px, 0deg, 188px, 64px, 0deg);
  }
}

.sbpDark {
  @include animation(481px, 69px, 42.569deg, 481px, 0px, 0deg);

  @include for-phone {
    width: 64px;
    height: auto;

    @include animation(326px, 69px, 42.569deg, 326px, 19px, 0deg);
  }
}

.tinkoff {
  @include animation(416px, 249px, -16.683deg, 415px, 169px, 0deg);

  @include for-phone {
    width: 62px;
    height: auto;

    @include animation(282px, 198px, -16.683deg, 282px, 134px, 0deg);
  }
}

.preProcessingBox {
  overflow: hidden;

  .boxImage {
    background-image: url("../../../assets/prime/box2-bg.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 325px 290px;
    position: relative;
    width: 549px;
    height: 304px;
    margin-left: auto;
    margin-right: auto;

    @include for-phone {
      background-position: 50% 10%;
      height: 226px;
    }
  }
}

.preProcessingText {
  margin-bottom: 46px;

  @include for-phone {
    margin-bottom: 20px;
  }
}
