@import "assets/css/variables.scss";

@value commissionSpritePercent, commissionSpriteZero, commissionSpriteZeroSmall from "../../../assets/commission-sprite.scss";
@value box, boxRight, boxWrap, boxImage, boxTitle, boxScrollable, boxTransition from "../../../components/Box/Box.module.scss";

$x0: 418px;
$y0: 0px;

%animation-common {
  position: absolute;
  transition:
    left ease-in-out 300ms,
    top ease-in-out 300ms;
  transform-origin: center;
}

@mixin animation($x1, $y1, $r1, $x2, $y2, $r2) {
  .commissionBox & {
    @extend %animation-common;
    left: $x1 - $x0;
    top: $y1 + $y0;
    transform: rotate($r1);
  }
  .commissionBox.boxTransition &,
  .commissionBox:not(.boxScrollable):hover & {
    left: $x2 - $x0;
    top: $y2 + $y0;
    transform: rotate($r2);
  }
}

.boxRight.commissionBox {
  overflow: hidden;
  grid-column: span 22;

  @include for-phone {
    flex-direction: column;
  }

  .boxImage {
    position: relative;
    width: 224px;
    height: 420px;

    @include for-phone {
      width: 100%;
      height: 267px;
    }

    @include for-tablet-s {
      width: 340px;
    }
  }

  .boxTitle {
    font-size: 50px;
    line-height: 60px;

    @include for-tablet {
      font-size: 38px;
      line-height: 48px;
    }

    @include for-phone {
      font-size: 24px;
      text-align: center;
      line-height: 32px;
    }
  }

  .boxWrap {
    @include for-phone {
      gap: 12px !important;
    }

    @include for-tablet {
      padding-bottom: 50px;
    }
  }
}

.commissionSpriteZero {
  @include animation(346px, 383.06px, 0deg, 346px, 353.06px, 0deg);

  @include for-phone {
    @include animation(426px, 383.06px, 0deg, 426px, 231.06px, 0deg);
  }
}

.commissionSpriteZeroSmall {
  @include animation(584px, 97.1px, 0deg, 584px, 77.1px, 0deg);

  @include for-phone {
    @include animation(695px, 97.1px, 0deg, 695px, 51.1px, 0deg);
  }
}

.commissionSpritePercent {
  @include animation(365.36px, 124.1px, 0deg, 365.36px, 84.1px, 0deg);

  @include for-phone {
    @include animation(490.36px, 124.1px, 0deg, 490.36px, 47.1px, 0deg);
  }
}
