@import "assets/css/variables.scss";

.radio {
  position: relative;

  &[data-checked="true"] {
    .label {
      &::after {
        display: block;
      }
    }
  }
}

.label {
  position: relative;
  border-radius: 5px;
  border: 1px solid #d6d9e1;
  background-color: #f8f8f8;
  padding: 19px 20px 19px 62px;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  min-height: 26px;
  display: block;

  &::before,
  &::after {
    content: "";
    background-color: #fff;
    border: 1px solid #d6d9e1;
    width: 24px;
    height: 24px;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 18px;
    left: 18px;
    box-sizing: content-box;
    z-index: 1;
  }

  &::after {
    display: none;
    width: 12px;
    height: 12px;
    background-color: #000;
    border-color: #000;
    transform: translate(6px, 6px);
    z-index: 2;
  }
}

.invalid {
  border-color: #f40000;

  &::before {
    border-color: #f40000;
  }
}

.input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;

  &:focus,
  &:focus-visible {
    & + .label {
      outline: 2px solid #1336fa;

      &::before {
        border-color: #1336fa;
      }
    }
  }
}
