@import "assets/css/variables.scss";

.connectionRequest {
  position: relative;
  padding: 100px 155px 40px;

  @include for-tablet {
    padding: 60px 20px 20px;
  }

  @include for-phone {
    padding: 60px 8px 10px;
  }

  h3 {
    margin-bottom: 30px;
    letter-spacing: -0.76px;
  }
}
