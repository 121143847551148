@import "assets/css/variables.scss";

.container {
  display: flex;
  margin-inline: auto;
  padding-inline: 8px;
  max-width: 1120px;
  box-sizing: content-box;
  background-repeat: no-repeat;
  background-position: top right;
  gap: 22px;

  @include for-phone-tablet-s {
    flex-direction: column;
  }

  &.centered {
    justify-content: center;
    align-items: center;
  }

  &.columned {
    flex-direction: column;
  }
}
