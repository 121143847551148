@import "assets/css/variables.scss";

.page {
  padding: 124px 0 22px;
  min-height: 100dvh;
  box-sizing: border-box;
  background-color: #fff;

  @include for-phone-tablet {
    padding: 98px 0 22px;
    background-image: none;

    &::before,
    &::after {
      display: none;
    }
  }

  @include for-phone {
    padding: 65px 0;
  }
}

.container {
  position: relative;
  max-width: 549px;
  margin: 0 auto;

  @include for-tablet {
    margin: 0 auto;
    padding: 0 20px;
  }

  @include for-phone {
    margin: 0 auto;
    padding: 0 8px;
  }
}

.title {
  margin-block: 40px 20px;
}

.subtitle {
  margin-bottom: 30px;

  a {
    color: #000;
  }
}

.back {
  font-size: 10px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
  padding-block: 3px;
  padding-inline: 7px 8px;
  text-decoration: none;
  border-radius: 40px;
  background-color: #eaeaea;
  color: #000;

  svg {
    margin-right: 7px;
  }
}
