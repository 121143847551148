@import "assets/css/variables.scss";
@import "../PrimaryBlock/primaryBlockLineGradient";
@value animationOffsetY, primaryBlockImage, primaryBlockContainer, primaryBlockBody, primaryBlockInfo, primaryBlockTransition from "../PrimaryBlock/PrimaryBlock.module.scss";

.primaryBlockProcessing {
  transition: background-position 1000ms ease-in-out;
  background-repeat: no-repeat;
  background-image: primaryBlockLineGradient(129.55deg, 172px, #0e1238),
    url("../../assets/prime/arrow-2.x1.5.png"),
    primaryBlockLineGradient(43.5deg, 189px, #0de5b6);
  background-position:
    60px 0,
    calc(50% + 244px) 128px,
    40px 0;
  background-size:
    100% 650px,
    343px 249px,
    100% 650px;

  &.primaryBlockTransition {
    background-position:
      0 0,
      calc(50% + -60px) 578px,
      0 0;
  }

  @include for-tablet {
    background-image: primaryBlockLineGradient(129.55deg, 138px, #0e1238),
      url("../../assets/prime/arrow-2.x1.5.png"),
      primaryBlockLineGradient(43.5deg, 205px, #0de5b6);

    .primaryBlockContainer {
      position: relative;
      z-index: 2;
      margin: 0;
    }
  }

  @include for-phone {
    border-radius: 0;
    height: 922px;

    background-image: linear-gradient(0deg, white, white),
      primaryBlockLineGradient(129.55deg, 0px, #0e1238),
      url("../../assets/prime/arrow-2.x1.5.png"),
      primaryBlockLineGradient(43.5deg, 205px, #0de5b6);

    background-size:
      100% calc(100% - 419px),
      100% 100%,
      239px auto,
      100% 100%;

    background-position:
      0 0,
      0 153px,
      calc(50% + -60px) 578px,
      -46px 503px;

    &.primaryBlockTransition {
      background-position:
        0 0,
        0 153px,
        calc(50% + -60px) 578px,
        -46px 503px;
    }
  }

  .primaryBlockBody {
    @include for-phone {
      padding-top: 7px;
      padding-bottom: 18px;
    }
  }

  .primaryBlockImage {
    @include for-phone {
      position: absolute;
      height: 419px;
    }
  }

  .primaryBlockInfo {
    @include for-phone {
      flex-flow: wrap;
      gap: 10px;
      padding-bottom: 19px;
    }
  }
}

.antifrod {
  left: 328px;
  top: 219px;
  transform: translate(-20px, -10px);
  width: 158px;
  height: 217px;

  @include for-tablet {
    left: 312px;
    top: 192px;
    width: 138px;
    height: auto;
  }

  @include for-phone {
    left: 150px;
    top: 140px;
    width: 109px;
    height: auto;
  }
}

.sbp {
  top: 63px;
  left: 376px;
  transform: translate(-15px, -62px) rotate(16deg);
  width: 107px;
  height: 124px;

  @include for-tablet {
    top: 55px;
    left: 354px;
    width: 95px;
    height: auto;
  }

  @include for-phone {
    top: 31px;
    left: 183px;
    width: 75px;
    height: auto;
  }
}

.sber {
  top: 494px;
  left: 53px;
  transform: translate(-16px, -6px) rotate(-14deg);
  width: 93px;
  height: 100px;

  @include for-tablet {
    top: 433px;
    left: 71px;
    width: 82px;
    height: auto;
  }

  @include for-phone {
    top: 331px;
    left: -42px;
    width: 65px;
    height: auto;
  }
}

.api {
  top: 166px;
  left: 495px;
  width: 114px;
  height: 116px;
  transform: translate(-31px, -5px);

  @include for-tablet {
    top: 147px;
    left: 459px;
    width: 97px;
    height: auto;
  }

  @include for-phone {
    top: 103px;
    left: 266px;
    width: 78px;
    height: auto;
  }
}

.aml {
  left: 219px;
  top: 294px;
  width: 115px;
  height: 120px;

  @include for-tablet {
    left: 211px;
    top: 255px;
    width: 107px;
    height: auto;
  }

  @include for-phone {
    left: 69px;
    top: 191px;
    width: 85px;
    height: auto;
  }
}

.bankCard {
  top: 414px;
  left: 196px;
  width: 245px;
  height: 92px;

  @include for-tablet {
    top: 363px;
    left: 196px;
    width: 216px;
    height: auto;
  }

  @include for-phone {
    top: 276px;
    left: 57px;
    width: 171px;
    height: auto;
  }
}

.transfer {
  top: 494px;
  left: 190px;
  width: 276px;
  height: 76px;

  @include for-tablet {
    top: 433px;
    left: 190px;
    width: 243px;
    height: auto;
  }

  @include for-phone {
    top: 331px;
    left: 51px;
    width: 196px;
    height: auto;
  }
}

.compliance {
  top: 343px;
  left: 428px;
  width: 204px;
  height: 209px;
  transform: translate(-20px, -6px);

  @include for-tablet {
    top: 301px;
    left: 399px;
    width: 178px;
    height: auto;
  }

  @include for-phone {
    top: 225px;
    left: 218px;
    width: 144px;
    height: auto;
  }
}
