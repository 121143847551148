.password {
  margin-bottom: 10px;
}

.formError {
  color: red;
  border: 1px solid red;
  padding: 10px;
  background: #fff2f2;
  border-radius: 5px;
  margin: 0 0 20px;

  p {
    margin: 0;
  }
}
