@import "assets/css/variables.scss";
@value boxWrap, boxRight, boxImage, boxTitle, boxContent from "../../components/Box/Box.module.scss";

.products {
  @include for-tablet-s {
    flex-direction: column;
  }
}

.countries {
  display: grid;
  grid-template-columns: repeat(36, 1fr);
  box-sizing: border-box;
  gap: 20px 22px;
  position: relative;

  @include for-phone {
    display: block;
  }

  @include for-tablet-s {
    grid-template-columns: repeat(24, 1fr);
    column-gap: 18px;
  }
}

.countryBox {
  justify-content: center;
  grid-column: span 24;
  overflow: hidden;

  @include for-phone {
    padding-bottom: 40px;
  }

  @include for-tablet {
    flex: 0 0 503px;
  }

  @include for-tablet-s {
    grid-column: span 12;
  }
}

.countryTransferBox {
  grid-column: span 12;

  .boxWrap {
    padding: 0;
  }

  @include for-phone {
    margin-top: -30px;
    margin-bottom: 20px;
  }

  .boxContent {
    padding-bottom: 50px;
  }
}

.techSupport {
  grid-column: span 36;
  overflow: hidden;

  @include for-tablet-s {
    grid-column: span 24;
  }

  .boxWrap {
    padding-top: 70px;

    @include for-phone-tablet {
      padding-top: 30px;
    }
  }

  &.boxRight {
    @include for-phone-tablet {
      flex-direction: column;

      & > div:first-child {
        gap: 0;
      }
    }

    .boxImage {
      margin-bottom: 62px;
      margin-top: 70px;
      margin-right: 39px;
      width: auto;
      height: auto;

      @include for-phone-tablet {
        position: absolute;
        transform: translate(-50%, calc(var(--vdd)));
        left: 50%;
        margin: 0;

        img {
          width: 182px;
          height: auto;
        }
      }
    }

    .boxTitle,
    .boxContent {
      padding-inline-end: 0;

      @include for-phone-tablet {
        margin-bottom: 20px;
        padding-inline: 30px;
        text-align: center;
      }
    }
  }
}
