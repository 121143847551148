@import "@fontsource/open-sans";
@import "normalize.css";
@import "./variables.scss";

* {
  margin: 0;
  padding: 0;
}

html {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: $font-size-normal;
  line-height: $line-height-normal;
  text-wrap: balance;
}

body {
  font-size: 14px;
  margin: 0;
  background: #f8f8f8;
}

a,
button {
  outline: none;
}

$color-1: #171a27;
$color-2: #000000;
$color-3: #ffffff;

h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 70px;
  letter-spacing: -1.2px;

  @include for-tablet {
    font-size: 50px;
    line-height: 60px;
    letter-spacing: -1px;
  }

  @include for-phone {
    font-size: 42px;
    line-height: 50px;
    letter-spacing: -0.84px;
  }
}

h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 60px;
  margin-block: 40px;

  @include for-phone {
    font-size: 30px;
    line-height: 40px;
    margin-block: 20px;
  }
}

h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 48px;

  @include for-phone {
    font-size: 24px;
    line-height: 32px;
  }
}

h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.5px;
}

h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;

  @include for-phone {
    font-size: 18px;
    line-height: 26px;
  }
}

p {
  font-size: $font-size-normal;
  line-height: $line-height-normal;
  margin-bottom: 24px;

  @include for-phone {
    margin-bottom: 17px;
  }
}

.mb-0 {
  margin-bottom: 0;
}

.mb-20 {
  margin-bottom: 20px;

  @include for-phone {
    margin-bottom: 19px;
  }
}

.mb-30 {
  margin-bottom: 30px;

  @include for-phone {
    margin-bottom: 19px;
  }
}

.ta-c {
  text-align: center;
}

.small {
  font-size: $font-size-small;
  line-height: $line-height-small;
  margin-bottom: 0;
}

.gray {
  color: #969696 !important;
}
