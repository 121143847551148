%mainFunctionSprite {
  background-image: url("./main-function-sprite.x1.5.png");
  background-repeat: no-repeat;
  background-size: 187px 408px;
}
.mainFunctionSpriteGreen {
  @extend %mainFunctionSprite;
  width: 187px;
  height: 158px;
  background-position: 0 0;
}
.mainFunctionSpriteBlue {
  @extend %mainFunctionSprite;
  width: 152px;
  height: 129px;
  background-position: 0 -158px;
}
.mainFunctionSpriteWhite {
  @extend %mainFunctionSprite;
  width: 145px;
  height: 122px;
  background-position: 0 -287px;
}
