//.switchEnter,
.switchExit {
  position: absolute;
  top: 68px;
  left: 0;
  right: 0;
}
.switchEnter {
  opacity: 0;
  z-index: 1;
}
.switchEnter.switchEnterActive {
  opacity: 1;
  transition: opacity 240ms ease-in;
}
.switchEnterDone {
}
.switchExit {
  opacity: 1;
}
.switchExit.switchExitActive {
  opacity: 0;
  transition: opacity 240ms ease-out;
}

.switchExitDone {
}

.switchAppear {
}
.switchAppearActive {
}
.switchAppearDone {
}
