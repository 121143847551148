@import "assets/css/variables.scss";

.info {
  margin-bottom: 65px;

  @include for-phone-tablet {
    margin-bottom: 249px;
  }
}

.items {
  display: flex;
  gap: 90px;

  // @include for-tablet {
  //   gap: 60px;
  // }

  @include for-phone-tablet {
    flex-direction: column;
    gap: 30px;
  }

  h4 {
    margin-bottom: 5px;
    white-space: nowrap;
  }

  a {
    color: #000;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.icon {
  margin-top: 5px;
}
