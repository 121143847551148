@import "assets/css/variables.scss";

.accordion {
  counter-reset: accordionIncrement;
  width: 100%;
  display: block;
  &.accordionCounter {
    padding-left: 30px;
    .accordionTitle {
      &:before {
        counter-increment: accordionIncrement;
        content: counter(accordionIncrement) ".";
        left: -24px;
        position: absolute;
        display: block;
        padding-right: 0.325em;
        width: 30px;
        align-items: self-start;
        height: 100%;
      }
    }
  }
  .accordionTitle {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 21px;
    line-height: 34px;
    font-weight: 400;
    margin-bottom: 30px;

    @include for-phone {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 20px;
    }

    &:after {
      content: "";
      background-image: url("../../assets/arrow-down.svg");
      transform: rotate(0deg);
      transform-origin: center;
      background-color: #eaeaea;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 14px 8px;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      margin-left: auto;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
  .accordionDescription {
    padding: 0;
    margin: 0;
    transition: height ease-in-out 300ms;
    height: 0;
    box-sizing: border-box;
    &:after {
      content: "";
      display: block;
      height: 20px;
    }
    display: none;
  }
  .accordionTitleShow {
    &:after {
      transform: rotate(180deg);
    }
  }
  .accordionDescriptionShow {
    display: block;
    height: auto;
  }
  .accordionDescriptionTransition {
    display: block;
  }
  .accordionDescriptionShowStart {
    display: block;
  }
  .accordionDescriptionHidePrepare {
    display: block;
  }
  .accordionDescriptionHideStart {
    display: block;
  }
}
