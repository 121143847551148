@import "assets/css/variables.scss";
@value box, boxImage, boxContent, boxScrollable, boxTransition from "../../../components/Box/Box.module.scss";

%whiteLabelAnimation {
  position: absolute;
  transition:
    left ease-in-out 300ms,
    top ease-in-out 300ms;
  transform-origin: center;
}

@mixin animation($x1, $y1, $x2, $y2) {
  .whiteLabel & {
    @extend %whiteLabelAnimation;
    left: calc(50% + $x1);
    top: $y1;
  }
  .whiteLabel.boxTransition &,
  .whiteLabel:not(.boxScrollable):hover & {
    left: calc(50% + $x2);
    top: $y2;
  }
}

.whiteLabel {
  overflow: hidden;
  grid-column: span 12;

  @include for-phone-tablet {
    flex: 0 0 350px;
  }

  .boxImage {
    position: relative;
    width: 100%;
    height: 187px;
  }

  .boxContent {
    padding-inline: 30px;
  }
}

img {
  @include animation(-83px, 2px, -83px, -8px);
}
