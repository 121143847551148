@import "assets/css/variables.scss";

.image {
  padding: 0 !important;
  background-image: var(--bg-image);
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  // background-repeat: repeat;
  // background-size: 866px 400px;
  // animation-name: countries;
  // animation-duration: 20s;
  // animation-iteration-count: infinite;
  // animation-timing-function: linear;

  @include for-phone-tablet {
    // background-position: 480px -43px;
    // background-size: 578px 263px;
    height: 182px;
  }
}

@keyframes countries {
  from {
    background-position: 500px 50%;
  }

  to {
    background-position: 0 50%;
  }
}
