@import "assets/css/variables.scss";

.group {
  margin-bottom: 40px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 0 0 20px;
}

.inputs {
  display: flex;
  gap: 20px;

  @include for-phone-tablet {
    flex-direction: column;
  }
}

.error {
  font-size: 12px;
  line-height: 18px;
  color: #f40000;
  margin: 0;
}
