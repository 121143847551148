.login {
  margin-bottom: 12px;
}

.password {
  margin-bottom: 5px;
}

.reset {
  text-align: end;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 35px;

  a {
    color: #000;
  }
}

.formError {
  color: red;
  border: 1px solid red;
  padding: 10px;
  background: #fff2f2;
  border-radius: 5px;
  margin: 0 0 20px;

  p {
    margin: 0;
  }
}
