$font-size-normal: 14px;
$line-height-normal: 22px;

$font-size-small: 12px;
$line-height-small: 18px;

@mixin for-phone {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin for-tablet {
  @media (min-width: 481px) and (max-width: 1024px) {
    @content;
  }
}

@mixin for-tablet-s {
  @media (min-width: 481px) and (max-width: 768px) {
    @content;
  }
}

@mixin for-phone-tablet-s {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin for-phone-tablet {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin for-desktop {
  @media (min-width: 1025px) {
    @content;
  }
}
