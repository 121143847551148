@import "assets/css/variables.scss";

@value riskMitigationSpriteAntifrod, riskMitigationSpriteAml, riskMitigationSpriteCompliance from "../../../assets/risk-mitigation-sprite.scss";
@value box, boxRight, boxWrap, boxTitle, boxContent, boxImage, boxScrollable, boxTransition from "../../../components/Box/Box.module.scss";

%componentsRiskMitigationAnimation {
  position: absolute;
  transition-property: left, top, opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 300ms;
}

@mixin animation($x1, $y1, $x2, $y2) {
  .componentsRiskMitigation & {
    @extend %componentsRiskMitigationAnimation;
    left: $x1;
    top: $y1;
  }
  .componentsRiskMitigation.boxTransition &,
  .componentsRiskMitigation:not(.boxScrollable):hover & {
    left: $x2;
    top: $y2;
  }
}

.componentsRiskMitigation {
  overflow: hidden;
  grid-column: span 36;

  @include for-phone {
    flex-direction: column !important;
  }

  &.boxRight {
    .boxWrap {
      width: 500px;
      padding-inline: 90px 0;
      box-sizing: border-box;
      text-align: left;

      @include for-phone {
        padding: 10px;
        text-align: center;
        width: auto;
      }

      .boxTitle {
        font-size: 50px;
        line-height: 60px;
        margin: 10px 0 0;
        padding: 0;

        @include for-phone {
          font-size: 38px;
          line-height: 42px;
        }
      }

      .boxContent {
        text-align: left;
        padding: 0;

        @include for-phone {
          padding-left: 50px;
        }
      }
    }

    .boxImage {
      position: relative;
      height: 420px;
      width: 593px;
      margin-left: auto;

      @include for-phone {
        height: 238px;
        width: 100%;
      }
    }
  }
}

.riskMitigationSpriteBg {
  .componentsRiskMitigation & {
    @extend %componentsRiskMitigationAnimation;
    left: -20px;
    top: -10px;
    opacity: 0;
  }
  .componentsRiskMitigation.boxTransition &,
  .componentsRiskMitigation:not(.boxScrollable):hover & {
    opacity: 1;
  }
}

.riskMitigationSpriteAntifrod {
  @include animation(129px, 114px, 129px, 44px);

  @include for-phone {
    @include animation(30px, 114px, 30px, 30px);
  }
}

.riskMitigationSpriteAml {
  @include animation(293px, -26px, 293px, -46px);

  @include for-phone {
    @include animation(293px, -26px, 293px, 39px);
  }
}

.riskMitigationSpriteCompliance {
  @include animation(181px, 172px, 181px, 142px);

  @include for-phone {
    @include animation(100px, 172px, 100px, 46px);
  }
}
