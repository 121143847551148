@import "assets/css/variables.scss";

@value accordion, accordionCounter from "../../components/Accordion/Accordion.module.scss";
@value boxWrap, boxRight, boxImage, boxTitle, boxContent from "../../components/Box/Box.module.scss";

.features {
  background-color: #fff;
  padding-block: 50px 26px;

  @include for-tablet {
    padding-top: 30px;
  }

  @include for-phone {
    padding-block: 30px 5px;
  }
}

.featuresItem {
  display: flex;
  list-style: none;
  justify-content: space-between;
  margin-inline: 95px;

  @include for-tablet {
    margin-inline: 22px;
    padding-top: 15px;
  }

  @include for-phone {
    flex-direction: column;
    margin-inline: 8px;
  }

  li {
    flex: 0 0 230px;

    @include for-tablet {
      flex: 0 0 calc(25% - 8px);
    }

    @include for-phone {
      flex: 1 1 auto;
      margin-bottom: 7px;
    }

    svg {
      margin-bottom: 14px;

      @include for-phone {
        float: left;
        margin-right: 20px;
        margin-bottom: 25px;
      }
    }

    h5 {
      margin-bottom: 20px;

      @include for-phone {
        margin-bottom: 10px;
      }
    }
  }
}

.sendMoneyTitle {
  text-align: center;
  margin-inline: 20px;

  @include for-phone {
    margin-bottom: 80px;
  }
}

.moneySteps {
  display: grid;
  grid-template-columns: repeat(36, 1fr);
  box-sizing: border-box;
  gap: 20px 22px;
  padding-inline: 95px;

  @include for-tablet {
    padding-inline: 8px;
    gap: 16px;
  }

  @include for-phone-tablet-s {
    display: block;
    padding-inline: 8px;
  }

  & > .moneyStep {
    @include for-phone-tablet-s {
      display: none;
    }
  }
}

.moneyStep {
  grid-column: span 18;

  @include for-phone {
    margin-inline: -42px -12px;
    margin-bottom: 40px;
  }
}

.moneyStepImage.boxImage {
  padding: 40px 8px;

  img {
    margin-inline: auto;
    display: block;
    width: var(--width);
  }

  @include for-phone {
    padding: 20px;

    img {
      width: 250px;
    }
  }
}

.countries {
  display: grid;
  grid-template-columns: repeat(36, 1fr);
  box-sizing: border-box;
  gap: 20px 22px;
  position: relative;

  @include for-phone {
    display: block;
  }

  @include for-tablet-s {
    grid-template-columns: repeat(24, 1fr);
    column-gap: 18px;
  }
}

.countryBox {
  justify-content: center;
  grid-column: span 24;
  overflow: hidden;

  @include for-phone {
    padding-bottom: 40px;
  }

  @include for-tablet {
    flex: 0 0 503px;
  }

  @include for-tablet-s {
    grid-column: span 12;
  }
}

.countryTransferBox {
  grid-column: span 12;

  .boxWrap {
    padding: 0;
  }

  @include for-phone {
    margin-top: -30px;
    margin-bottom: 20px;
  }

  .boxContent {
    padding-bottom: 50px;
  }
}

.sendMoneySubtitle {
  margin-bottom: 40px;

  @include for-phone {
    position: absolute;
    top: -60px;
    padding-inline: 0 !important;
    text-align: center;
    width: 100%;
  }
}

.sendMoneyBox.boxContent {
  padding-right: 70px;

  @include for-phone {
    padding-left: 20px;
    padding-right: 50px;
  }

  .moneyStep {
    display: none;
    margin-bottom: 8px;

    @include for-phone-tablet-s {
      display: block;
    }
  }
}

.steps {
  flex-direction: column;

  @include for-phone {
    padding: 0;
  }
}

.faq {
  display: grid;
  grid-template-columns: repeat(36, 1fr);
  box-sizing: border-box;
  gap: 20px 22px;
  padding-top: 20px;
  padding-left: 95px;
  padding-right: 95px;

  @include for-tablet {
    padding: 20px 8px 0;
  }

  @include for-phone-tablet-s {
    display: block;
    padding: 30px 8px;
  }

  h2 {
    font-size: 50px;
    grid-column: span 13;
    margin: 0;

    @include for-phone-tablet-s {
      font-size: 30px;
      line-height: 40px;
      text-align: center;
      margin-bottom: 20px;
    }
  }
}

.faqBox {
  grid-column: span 23;

  .boxContent {
    padding-inline: 20px;
  }
}
