@import "assets/css/variables.scss";
@value menu from "../Menu/Menu.module.scss";
@value container from "../Container/Container.module.scss";

.footer {
  &.container {
    padding: 77px 40px 102px;
    display: flex;
    box-sizing: border-box;
    gap: 225px;

    @include for-tablet {
      gap: 162px;
    }

    @include for-phone {
      position: relative;
      padding: 33px 12px 168px;
      gap: 30px;
    }
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .menu {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include for-phone {
      gap: 30px;
    }

    & > a {
      padding: 0;
      font-weight: 400;
      border: none;
    }
  }
}

.firstColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @include for-phone {
    margin-bottom: 32px;
  }
}

.secondColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 46px;

  @include for-phone {
    gap: 42px;
  }
}

.thirdColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.copyright {
  @include for-phone {
    position: absolute;
    bottom: 114px;
  }
}
