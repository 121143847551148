@import "assets/css/variables.scss";

.coinSprite {
  background-image: url("./coin-sprite.x1.5.png");
  background-repeat: no-repeat;
  background-position-x: 0;
  background-size: 89px 543px;

  @include for-phone {
    background-size: 62px 374px;
  }

  &.coinSpriteKyrgyzstan {
    width: 43px;
    height: 82px;
    background-position-y: 0;

    @include for-phone {
      width: 33px;
      height: 56px;
    }
  }
  &.coinSpriteRussian {
    width: 59px;
    height: 64px;
    background-position-y: -420px;
  }
  &.coinSpriteCrypto {
    width: 57px;
    height: 59px;
    background-position-y: -485px;
  }
  &.coinSpriteUzbekistan {
    width: 70px;
    height: 80px;
    background-position-y: -272px;

    @include for-phone {
      width: 51px;
      height: 56px;
      background-position-y: -187px;
    }
  }
  &.coinSpriteArmenia {
    width: 89px;
    height: 99px;
    background-position-y: -82px;

    @include for-phone {
      width: 62px;
      height: 66px;
      background-position-y: -56px;
    }
  }
  &.coinSpriteKazakhstan {
    width: 80px;
    height: 90px;
    background-position-y: -182px;

    @include for-phone {
      width: 58px;
      height: 60px;
      background-position-y: -124px;
    }
  }
  &.coinSpriteTajikistan {
    width: 65px;
    height: 68px;
    background-position-y: -352px;

    @include for-phone {
      width: 46px;
      height: 45px;
      background-position-y: -244px;
    }
  }
}
