@import "assets/css/variables.scss";
@import "primaryBlockLineGradient";

@value switchEnterDone from "../../layouts/Layout.module.scss";
@value coinSprite from "../../assets/coin-sprite.scss";

.primaryBlock {
  margin-top: -68px;
  height: 650px;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  border-radius: 0 0 20px 20px;
  box-sizing: border-box;

  @include for-tablet {
    height: 570px;
    margin-top: -50px;
  }

  @include for-phone {
    margin-top: -42px;
  }

  > .primaryBlockArrow {
    position: absolute;
    left: calc(50% - 19px);
    bottom: 23px;
    display: flex;
    outline: none;
    background: #ffffff;
    border: none;
    color: #000000;
    width: 38px;
    height: 38px;
    border-radius: 38px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 25%);
    justify-content: center;
    align-items: center;
    z-index: 1;

    &:active {
      transform: translateY(1px);
      box-shadow: 0 1px 5px rgba(0, 0, 0, 25%);
    }
  }

  .animationOffsetY {
    transition: transform 1000ms ease-in-out;
  }

  &.primaryBlockTransition {
    .animationOffsetY {
      transform: translate(0) rotate(0deg) !important;
    }
  }
}

.primaryBlockContainer {
  padding-top: 120px;
  flex-direction: column;
  gap: 0;

  @include for-tablet {
    height: 570px;
    padding-top: 90px;
    padding-inline: 32px;
  }

  @include for-phone {
    height: 355px;
    padding-top: 48px;
    padding-inline: 20px;
  }
}

.primaryBlockTitle,
.primaryBlockBody,
.primaryBlockInfo,
.primaryBlockFooter {
  max-width: 548px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;

  @include for-phone {
    width: auto;
    padding-top: 11.75px;
    padding-bottom: 11.75px;
  }
}

.primaryBlockTitle {
  color: #171a27;
  margin: 0;
}

.primaryBlockBody {
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  color: #000;
  display: flex;
  flex-direction: column;

  @include for-phone {
    font-size: 18px;
    line-height: 26px;
  }
}

.primaryBlockInfo {
  display: flex;
  gap: 20px;
  padding-top: 0;

  .primaryBlockInfoItem {
    display: flex;
    gap: 20px;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
  }
}

.primaryBlockFooter {
  display: flex;
  gap: 27px;
}

.primaryBlockImage {
  position: absolute;
  bottom: 0;
  left: calc(50% - 67px);
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;

  @include for-phone {
    position: relative;
    height: 423px;
    left: 0;
  }

  > img,
  > svg {
    position: absolute;
    z-index: 1;
    transition: transform 900ms ease-in-out;
  }
}

.switchEnterDone {
  .primaryBlockImageBgBack {
    transform: translate(100px, 100px);
  }
}
