@import "assets/css/variables.scss";

.page {
  position: relative;
  padding: 124px 0 22px 50%;
  width: 100dvw;
  min-height: 100dvh;
  box-sizing: border-box;
  background-color: #fff;
  background-image: linear-gradient(
      -56deg,
      transparent,
      transparent calc(50% + 193px),
      #eeff03 calc(50% + 193px),
      #f0fa03 100%
    ),
    linear-gradient(
      -136.5deg,
      transparent,
      transparent calc(50% + 186px),
      #0de5b6 calc(50% + 186px),
      #0de5b6 100%
    );
  background-position:
    center 0,
    center 0;
  background-size:
    100% 100%,
    100% 100%;
  background-repeat: no-repeat;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-image: var(--bg-image);
    background-position: 50% calc(50% - 70px);
    background-repeat: no-repeat;
    background-size: 85%;
  }

  @include for-phone-tablet {
    padding: 98px 0 22px;
    background-image: none;

    &::after {
      display: none;
    }
  }

  @include for-phone {
    padding: 65px 0;
  }
}

.container {
  position: relative;
  max-width: 417px;
  margin: 0 auto 0 0;
  padding: 0 0 0 75px;

  @include for-tablet {
    margin: 0 auto;
    padding: 0 20px;
  }

  @include for-phone {
    margin: 0 auto;
    padding: 0 8px;
  }

  label {
    margin-bottom: 12px;
  }
}

.title {
  margin-bottom: 20px;
}

.subtitle {
  margin-bottom: 30px;

  a {
    color: #000;
  }
}

.benefits {
  list-style: none;
  position: absolute;
  left: 25%;
  top: 20dvw;
  z-index: 1;
  transform: translate(-50%, 350px);

  @include for-phone-tablet {
    display: none;
  }

  li {
    background-repeat: no-repeat;
    font-weight: 600;
    padding-left: 50px;
    line-height: 38px;
    margin-bottom: 10px;
  }
}

.minSetup {
  background-image: url(../../assets/icon-min-setup.svg);
}
.freeConnect {
  background-image: url(../../assets/icon-free-connection.svg);
}
.support {
  background-image: url(../../assets/icon-support2.svg);
}

.formError {
  color: red;
  border: 1px solid red;
  padding: 10px;
  background: #fff2f2;
  border-radius: 5px;
  margin: 0 0 20px;

  p {
    margin: 0;
  }
}

.buttons {
  display: flex;
  gap: 10px;
}

.resendButton {
  background-color: transparent;
  border: none;
  font-size: inherit;
  font-family: inherit;
  padding: 0 0 0 5px;
  text-decoration: underline;
  cursor: pointer;
}
