@import "assets/css/variables.scss";
@import "../PrimaryBlock/primaryBlockLineGradient";
@value animationOffsetY, primaryBlockTransition, primaryBlockContainer, primaryBlockImage from "../PrimaryBlock/PrimaryBlock.module.scss";

.primaryBlockHome {
  background-image: primaryBlockLineGradient(129.55deg, 172px, #eeff03),
    primaryBlockLineGradient(43.5deg, 189px, #0de5b6),
    url("../../assets/prime/arrow-1.x1.5.png");
  transition: background-position 1000ms ease-in-out;
  background-position:
    60px 0,
    14px 0,
    calc(50% + 130px) 175px;
  background-repeat: no-repeat;
  background-size:
    100% 650px,
    100% 650px,
    230px 165px;

  @include for-tablet {
    background-size:
      100% 100%,
      100% 110%,
      203px auto;

    .primaryBlockContainer {
      position: relative;
      z-index: 2;
      margin: 0;
    }

    .primaryBlockImage {
      right: calc(-5dvw);
      width: 50%;
      left: auto;
    }
  }

  @include for-phone {
    height: auto;
    background-image: linear-gradient(
        -50.45deg,
        #eeff03,
        #eeff03 calc(50% + 190px),
        transparent calc(50% + 190px),
        transparent 100%
      ),
      linear-gradient(
        43.5deg,
        transparent,
        transparent calc(50% + 134px),
        #0de5b6 calc(50% + 134px),
        #0de5b6 100%
      ),
      url("../../assets/prime/arrow-1.x1.5.png");
    background-size:
      100% 100%,
      100% 100%,
      163px auto;
  }

  &.primaryBlockTransition {
    background-position:
      0 0,
      0 0,
      calc(50% + 90px) 185px;

    @include for-tablet {
      background-position:
        0 0,
        0 0,
        calc(50% + 91px) 179px;
    }

    @include for-phone {
      background-position:
        0 403px,
        0 403px,
        -48px 500px;
    }

    @media (min-width: 481px) and (max-width: 750px) {
      .support.animationOffsetY {
        transform: translate(50px, 70px) !important;
      }
    }
  }
}

.support {
  width: 132px;
  height: 132px;
  left: 90px;
  top: 397px;
  transform: translate(2px, 0);

  @include for-tablet {
    left: 101px;
    top: 348px;
    width: 116px;
    height: auto;
  }

  @include for-phone {
    width: 91px;
    height: auto;
    left: -14px;
    top: 246px;
  }
}

.interfaceCommon {
  width: 287px;
  height: 478px;
  left: 255px;
  top: 87px;

  @include for-tablet {
    left: 247px;
    top: 77px;
    width: 250px;
    height: auto;
  }

  @include for-phone {
    width: 199px;
    height: auto;
    left: 101px;
    top: 29px;
  }
}

.antifrod {
  width: 101px;
  height: 139px;
  left: 161px;
  top: 288px;
  transform: translate(-11px, -3px);

  @include for-tablet {
    left: 164px;
    top: 253px;
    width: 89px;
    height: auto;
  }

  @include for-phone {
    width: 71px;
    height: auto;
    left: 35px;
    top: 168px;
  }
}

.control {
  width: 377px;
  height: 488px;
  bottom: 24px;
  left: 186px;
  transform: translate(-10px, 0);

  @include for-tablet {
    bottom: 20px;
    left: 186px;
    width: 331px;
    height: auto;
  }

  @include for-phone {
    width: 264px;
    height: auto;
    bottom: 16px;
    left: 53px;
  }
}

.compliance {
  width: 172px;
  height: 177px;
  top: 456px;
  left: 505px;
  transform: translate(-26px, -12px);

  @include for-tablet {
    top: 401px;
    left: 466px;
    width: 150px;
    height: auto;
  }

  @include for-phone {
    width: 120px;
    height: auto;
    top: 287px;
    left: 276px;
  }
}

.done {
  width: 108px;
  height: 144px;
  top: 112px;
  left: 525px;
  transform: translate(-10px, 0);

  @include for-tablet {
    top: 100px;
    left: 485px;
    width: 93px;
    height: auto;
  }

  @include for-phone {
    width: 74px;
    height: auto;
    top: 48px;
    left: 291px;
  }
}

.api {
  width: 114px;
  height: 116px;
  top: 295px;
  left: 516px;
  transform: translate(-22px, 1px);

  @include for-tablet {
    top: 260px;
    left: 477px;
    width: 98px;
    height: auto;
  }

  @include for-phone {
    width: 76px;
    height: auto;
    top: 176px;
    left: 285px;
  }
}

.aml {
  width: 84px;
  height: 86px;
  top: 359px;
  left: 592px;
  transform: translate(-20px, -10px);

  @include for-tablet {
    top: 316px;
    left: 541px;
    width: 70px;
    height: auto;
  }

  @include for-phone {
    width: 60px;
    height: auto;
    top: 217px;
    left: 336px;
  }
}
