@import "assets/css/variables.scss";
@value btn from "../Button/Button.module.scss";

.header {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100%;
  height: 68px;
  border-radius: 0 0 20px 20px;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.04);
  padding: 0 30px;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  z-index: 5;
  max-width: 1440px;
  margin: 0 auto;

  @include for-tablet {
    border-radius: 0;
    padding: 0 10px;
    height: 50px;
  }

  @include for-phone {
    border-radius: 0;
    padding: 0 8px;
    height: 42px;
  }

  &.active {
    @include for-phone-tablet {
      &::before {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fcfcfd;
        border-bottom: 0.5px solid #d6d9e1;
        position: absolute;
        z-index: 2;
      }

      backdrop-filter: none;

      .buttons {
        display: flex;

        .connect,
        .login {
          display: block;
        }
      }

      .contacts {
        display: flex;
      }
    }
  }
}

.home {
  z-index: 4;
}

.logo {
  display: block;

  @include for-phone-tablet {
    width: 46px;
  }
}

.contacts {
  display: none;
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 20px;
  list-style: none;
  top: 344px;
  align-items: flex-end;
  justify-content: space-between;
  box-sizing: border-box;

  p {
    &:first-child {
      margin-bottom: 6px;
      font-size: 12px;
      line-height: 18px;
    }

    &:last-child {
      margin: 0;
      font-size: 21px;
    }
  }

  .icon {
    display: block;

    svg {
      vertical-align: bottom;
    }
  }
}

.buttons {
  .login {
    @include for-phone-tablet {
      display: none;
    }
  }

  .connect {
    display: none;
  }

  @include for-phone-tablet {
    display: none;
    background: #e8e9f0;
    width: 100%;
    padding: 40px 10px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: absolute;
    left: 0;
    box-sizing: border-box;
    border-radius: 0 0 20px 20px;
    bottom: -545px;
    align-items: stretch;
  }
}

.menu {
  width: 24px;
  height: 24px;
  border-radius: 30px;
  background: #eaeaea;
  padding: 0;
  overflow: hidden;
  text-indent: -200%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  z-index: 3;

  &::before,
  &::after {
    border-radius: 2px;
    border: 1px solid #000;
    width: 12px;
    height: 0;
    content: "";
  }

  @include for-desktop {
    display: none;
  }
}
