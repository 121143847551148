@import "assets/css/variables.scss";

@value boxImage from "../../../components/Box/Box.module.scss";
@value coinSprite, coinSpriteKyrgyzstan, coinSpriteRussian, coinSpriteCrypto, coinSpriteUzbekistan, coinSpriteArmenia, coinSpriteKazakhstan, coinSpriteTajikistan from "../../../assets/coin-sprite.scss";

%animation-common {
  position: absolute;
  transition:
    transform ease-in-out 300ms,
    left ease-in-out 300ms,
    top ease-in-out 300ms;
  transform-origin: center;
}

@mixin animation($x1, $y1, $r1, $x2, $y2, $r2) {
  .onlineTransferBox & {
    @extend %animation-common;
    left: $x1;
    top: $y1;
    transform: rotate($r1);
  }

  .onlineTransferBox:hover & {
    left: $x2;
    top: $y2;
    transform: rotate($r2);
  }
}

.mobile {
  @include animation(88px, 59px, 0deg, 88px, 28px, 0deg);

  @include for-phone {
    width: 179px;
    height: auto;
    @include animation(60px, 59px, 0deg, 60px, 25px, 0deg);
  }
}

.coinSpriteUzbekistan {
  @include animation(10px, 164px, 13.01deg, -12px, 113px, 0deg);

  @include for-phone {
    @include animation(10px, 146px, 13.01deg, -7px, 81px, 0deg);
  }
}

.coinSpriteKyrgyzstan {
  @include animation(475px, 54px, 11.895deg, 495px, 12px, 0deg);

  @include for-phone {
    @include animation(323px, 54px, 11.895deg, 336px, 14px, 0deg);
  }
}

.coinSpriteTajikistan {
  @include animation(381px, 105px, 15deg, 390px, 54px, 15deg);

  @include for-phone {
    @include animation(265px, 105px, 15deg, 265px, 44px, 15deg);
  }
}

.coinSpriteKazakhstan {
  @include animation(317px, 174px, 27.851deg, 316px, 113px, 45deg);

  @include for-phone {
    @include animation(213px, 134px, 27.851deg, 216px, 82px, 45deg);
  }
}

.coinSpriteArmenia {
  @include animation(372px, 262px, 44.522deg, 372px, 191px, 30deg);

  @include for-phone {
    @include animation(252px, 179px, 44.522deg, 252px, 135px, 29deg);
  }
}

.onlineTransferBox {
  .boxImage {
    position: relative;
    width: 549px;
    height: 304px;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;

    @include for-phone {
      height: 210px;
    }
  }
}
