@import "assets/css/variables.scss";
@import "../PrimaryBlock/primaryBlockLineGradient";
@value animationOffsetY, primaryBlockTransition, primaryBlockContainer, primaryBlockImage from "../PrimaryBlock/PrimaryBlock.module.scss";
@value coinSprite, coinSpriteKyrgyzstan, coinSpriteRussian, coinSpriteCrypto, coinSpriteUzbekistan, coinSpriteArmenia, coinSpriteKazakhstan, coinSpriteTajikistan from "../../assets/coin-sprite.scss";

.primaryBlockOnline {
  background-image: primaryBlockLineGradient(129.55deg, 172px, #0de5b6),
    primaryBlockLineGradient(43.5deg, 189px, #0029ff),
    url("../../assets/prime/arrow-2.x1.5.png");
  transition: background-position 1000ms ease-in-out;
  background-position:
    60px 0,
    40px 0,
    calc(50% + 163px) 392px;
  background-repeat: no-repeat;
  background-size:
    100% 650px,
    100% 650px,
    267px 194px;

  &.primaryBlockTransition {
    background-position:
      0 0,
      0 0,
      calc(50% + 63px) 392px;
  }

  @include for-tablet {
    .primaryBlockContainer {
      position: relative;
      z-index: 2;
      margin: 0;
    }
  }

  @include for-phone {
    border-radius: 0;
    height: 773px;

    background-image: linear-gradient(0deg, white, white),
      primaryBlockLineGradient(129.55deg, 172px, #0de5b6),
      url("../../assets/prime/arrow-2.x1.5.png"),
      primaryBlockLineGradient(43.5deg, 103px, #0029ff);

    background-size:
      100% 353px,
      100% 1137px,
      186px 135px,
      100% 100%;

    background-position:
      0 0,
      90% 90%,
      -179px 610px,
      90% 253px;

    &.primaryBlockTransition {
      background-position:
        0 0,
        100% 100%,
        -79px 610px,
        100% 353px;
    }

    .primaryBlockContainer {
      height: 305px;
    }

    .primaryBlockImage {
      height: 420px;
    }
  }

  .interfaceCommon {
    width: 287px;
    height: 478px;
    left: 254px;
    top: 87px;

    @include for-phone {
      width: 200px;
      height: auto;
      left: 98px;
      top: 44px;
    }
  }

  .control2 {
    width: 322px;
    height: 488px;
    left: 241px;
    bottom: 24px;
    transform: translate(-10px, 0);

    @include for-phone {
      width: 225px;
      height: auto;
      left: 88px;
      bottom: -1px;
    }
  }

  .coinSprite {
    position: absolute;
    z-index: 1;
    transition-property: left, top, transform;
    transition-duration: 1000ms;
    transition-timing-function: ease-in-out;
  }

  .coinSpriteKyrgyzstan {
    left: 330px;
    top: 117px;
    transform: rotate(5.74deg);

    @include for-phone {
      left: 150px;
      top: 74px;
    }
  }

  &.primaryBlockTransition {
    .coinSpriteKyrgyzstan {
      left: 330px;
      top: 87px;
      transform: rotate(18.14deg);

      @include for-phone {
        left: 150px;
        top: 44px;
      }
    }
  }

  .coinSpriteRussian {
    left: 525px;
    top: 99px;
    transform: rotate(-9.37deg);

    @include for-phone {
      left: 284px;
      top: 46px;
      background-position-y: -289px;
      width: 41px;
      height: 45px;
    }
  }

  &.primaryBlockTransition {
    .coinSpriteRussian {
      left: 535px;
      top: 119px;
      transform: rotate(-15deg);

      @include for-phone {
        left: 294px;
        top: 66px;
      }
    }
  }

  .coinSpriteCrypto {
    left: 560px;
    top: 220px;
    transform: rotate(-96deg);

    @include for-phone {
      left: 307px;
      top: 127px;
      background-position-y: -334px;
      transform: rotate(-46deg);
      width: 40px;
      height: 41px;
    }
  }

  &.primaryBlockTransition {
    .coinSpriteCrypto {
      left: 580px;
      top: 250px;
      transform: rotate(-45deg);

      @include for-phone {
        left: 327px;
        top: 157px;
      }
    }
  }

  .coinSpriteUzbekistan {
    left: 541px;
    top: 253px;
    transform: rotate(-17.18deg);

    @include for-phone {
      left: 299px;
      top: 149px;
      background-position-y: -186px;
    }
  }

  &.primaryBlockTransition {
    .coinSpriteUzbekistan {
      left: 543px;
      top: 296px;
      transform: rotate(0deg);

      @include for-phone {
        left: 299px;
        top: 189px;
      }
    }
  }

  .coinSpriteArmenia {
    left: 181px;
    top: 434px;
    transform: rotate(-30.32deg);

    @include for-phone {
      left: 46px;
      top: 300px;
    }
  }

  &.primaryBlockTransition {
    .coinSpriteArmenia {
      left: 181px;
      top: 395px;
      transform: rotate(-9.23deg);

      @include for-phone {
        left: 46px;
        top: 259px;
      }
    }
  }

  .coinSpriteKazakhstan {
    left: 131px;
    top: 321px;
    transform: rotate(10.99deg);

    @include for-phone {
      left: 12px;
      top: 216px;
    }
  }

  &.primaryBlockTransition {
    .coinSpriteKazakhstan {
      left: 130px;
      top: 292px;
      transform: rotate(-5.56deg);

      @include for-phone {
        left: 12px;
        top: 186px;
      }
    }
  }

  .coinSpriteTajikistan {
    left: 196px;
    top: 239px;
    transform: rotate(-50deg);

    @include for-phone {
      left: 57px;
      top: 157px;
    }
  }

  &.primaryBlockTransition {
    .coinSpriteTajikistan {
      left: 196px;
      top: 219px;
      transform: rotate(-58deg);

      @include for-phone {
        left: 57px;
        top: 137px;
      }
    }
  }
}
