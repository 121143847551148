.dialog {
  border-radius: 0 0 20px 20px;
  background-color: #fff;
  border: none;
  margin: 0 auto;
  width: 100dvw;
  position: fixed;
  max-width: none;

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .close {
    position: absolute;
    top: 5px;
    right: 20px;
    width: 60px;
    height: 60px;
    padding: 15px;
    border: none;
    background: transparent;
    z-index: 2;
    cursor: pointer;

    &:focus,
    &:focus-visible {
      outline: 1px solid #1336fa;
    }
  }
}
